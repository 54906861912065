import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ReactNode } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

interface IControlledCannelCheckbox<T extends FieldValues> {
  name: string;
  label: ReactNode;
  control: Control<T>;
  errors: any;
  isDirty: boolean;
  controllerProps?: any;
  checkboxProps?: any;
  disabled?: boolean;
}

function ControlledCheckbox<T extends FieldValues>({
  name,
  label,
  control,
  controllerProps,
  checkboxProps,
  disabled,
}: IControlledCannelCheckbox<T>) {
  return (
    <FormGroup>
      <FormControlLabel
        label={label}
        control={
          <Controller
            {...controllerProps}
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                {...checkboxProps}
                disabled={disabled}
                checked={Boolean(value)}
                onChange={(e) => {
                  onChange(e.target.checked);
                  checkboxProps?.onChange?.(e.target.checked);
                }}
              />
            )}
          />
        }
      />
    </FormGroup>
  );
}

export default ControlledCheckbox;
